@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
html.dark {
  background-color: rgb(39 39 42);
}
